$pink			  	  = #ff80ab
$blue			  	  = #4cbbf0
$color_black          = #000
$color_base_white     = #fff
$color_grey 		  = #363636
$footer_color		  = #0d596d


/**
 *  Extra Light, Ultra Light = 100
 *  Light, Thin = 200
 *  Book, Demi = 300
 *  Normal, Regular = 400
 *  Medium = 500
 *  SemiBold, Demibold = 600
 *  Bold = 700
 *  Black, ExtraBold, Heavy = 800
 *  Extra Black, Fat, Poster, UltraBlack = 900
 */

/*
 * Font mixin
 */
webfont($family, $file, $weight = normal, $style = normal) {
    @font-face {
        font-family: $family;
        src: url("../fonts/" + $file + ".woff") format("woff"),
             url("../fonts/" + $file + ".ttf") format("truetype"); // @stylint ignore
        font-weight: $weight;
        font-style: $style;
    }
}

/**
 * Embedded fonts
 */
// Font Name
// Font Style
webfont("OpenSans", "OpenSans-Light", 100, normal);
webfont("OpenSans", "OpenSans-LightItalic", 100, italic);
webfont("OpenSans", "OpenSans-Regular", 400, normal);
webfont("OpenSans", "OpenSans-Italic", 400, italic);
webfont("OpenSans", "OpenSans-Semibold", 600, normal);
webfont("OpenSans", "OpenSans-SemiboldItalic", 600, italic);
webfont("OpenSans", "OpenSans-Bold", 700, normal);
webfont("OpenSans", "OpenSans-BoldItalic", 700, italic);
webfont("OpenSans", "OpenSans-ExtraBold", 800, normal);
webfont("OpenSans", "OpenSans-ExtraBoldItalic", 800, italic);
//Helpfull functions


body {
	font-family: "OpenSans";
}
body {
	for num in (1..6) {
		h{num} {

			color: $color_base_white;
		}
	}
}


// Helpfull margins and paddings 
mt(n) {
	margin-top: n;
}

mb(n) {
	margin-bottom: n;
}

border-radius(n) {
	-webkit-border-radius: n;
	-moz-border-radius: n;
	border-radius: n;
}

//Input Placeholder
placeholder() {
    &::-webkit-input-placeholder {
        {block}
    }
    &:-moz-placeholder {
        {block}
    }
    &::-moz-placeholder {
        {block}
    }
    &:-ms-input-placeholder {
        {block}
    }
}

//Button sizes
size($font, $padding, $size) {
	font-size: $font;
	padding: $padding;
	width: $size;
	margin: 0 auto;
}

.input-successs {
    border: 2px solid $blue;
}
.input-error {
    border: 2px solid #ff0000;
}

.pink-bg {
	background-color: $pink;
}

.blue-bg {
	background-color: $blue;
}
a {
	color: $blue;
	text-decoration: underline;
}

