.review {

	#review-text {
		height: 2.4em;
		overflow: hidden;
		position: relative;
		padding-right: 20px;
		transition: all .2s ease;
		font-size: 16px;
		color: $color_base_white;
	}
	textarea {
		height: 238px;
	}
	#review-text.open {
		height: auto;
	}
	.read-more-button {
		position: absolute;
		right: 10px;
		color: $color_base_white;
		font-size: 1.5em;
		cursor: pointer;
		transition: all .2s ease;
	}
	.read-more-button.open {
		transform: rotateX(180deg);
		top: -3px;
	}
	&-user-info {
		text-align: center;
		img {
			border-radius(50%);
		}
		p {
			margin: 10px 0px;
			color: $color_base_white;
			font-weight: 500;
		}
	}
	.review-box {
		padding: 15px 0px;
		margin: 15px 0px;
		color: $color_base_white;
	}
	
	.select {
		width: 40%;
		margin-left: 20px;
	}
	
	&-stars {
		color: $pink;
	}
	form {
		margin: 20px 0px;
	}
}