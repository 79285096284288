//Reset 
ul {
	list-style-type: none;
	padding: 0px;
	li {
		display: inline-block;
	}
}

.dropit {
	position: absolute;
    top: 25px;
    right: 0;
	li {
		float: left;
		display: inline-block;
		padding: 0px 10px;
		position: relative;
		a {
			color: $color_base_white;
			font-size: 16px;
		}
	}
}
.nav-drop {
	.fa-angle-down,
	.fa-angle-up {
		position: absolute;
	    right: 8px;
	    line-height: inherit;
	    top: 3px;
		right: -5px;
	    font-size: 90%;
	    transition: .3s;
	}
	.fa-angle-up {
	    top: 5px;
	    opacity: 0;
	}
	&.dropit-open {
		.fa-angle-up {
	    	top: 0;
	    	opacity: 1;
		}
		.fa-angle-down {
		    top: 5px;
		    opacity: 0;
		}
	}
	.dropit-submenu {
		padding: 0px;
    	margin: 0px;
		position: absolute;
		left: 0px;
		background-color: $pink;
		z-index: 10;
		li {
			display: block;
			float: none;
			
		}
	}
}

.header {
	min-height: 60px;
	background-color: $pink;
	
	.logo {
		float: left;
    	width: 100%;
	}
	.search-box {
		background-color: transparent;
		border: none
		border-bottom: 1px solid $color_base_white;
		transition: 0.2s all;
		color: $color_base_white;
		+placeholder() {
			color: $color_base_white;
		}
		&:focus {
			border-bottom: 2px solid $color_base_white;
			outline: none;
		}
	}
}
main {
	margin-bottom: 15px;
}
.grey-bg {
	background-color: $blue;
}

.pink-bg {
	background-color: $pink;
}

.white-bg {
	background-color: $color_base_white;
}

.submenu {
	text-align: center;
    margin: 0 auto;
	li {
		font-size: 16px;
		width: 13.5%;
		text-align: center;
		a {
			color: $color_base_white;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}	
	}
}

.text-center {
	text-align: center;
}
#slideshow { 
    position: relative;
	height: 450px;
	.slide {
		background-size:cover;
		width: 100%;
    	height: 100%;
    	background-position: 0px;
	}
	.slide1 {
		background-image: url('../images/backgrounds/1.jpg');

	}
	.slide2 {
		background-image: url('../images/backgrounds/2.jpg');
	}
	.slide3 {
		background-image: url('../images/backgrounds/3.jpg');
		
	}
}

#slideshow > div { 
    position: absolute;
    height: 450px;

    width:100%;
    img {
    	width: 100%;
    }
}
.bg-mask {
	background-color: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	&-over {
		position: relative;
		z-index: 100;
	}
}
.absolute {
	position: absolute;
	z-index: 100;
    width: 100%;
	margin-top: 30px;
	img {
		width: 220px;
	}
	.bigimg {
		width: 300px!important;
		&:hover {
			animation-name: stretch;
			animation-duration: 1.5s; 
			animation-timing-function: ease-out; 
			animation-delay: 0;
			animation-direction: alternate;
			animation-iteration-count: infinite;
			animation-fill-mode: none;
			animation-play-state: running;
		}
	}
	
	@keyframes stretch {
	0% {
		transform: scale(.7);

			
		}
		50% {
			
		}
		100% {
			transform: scale(1);
			
		}
	}
	h3 {
		span {
			font-size: 150%;
			font-weight: 600;
		}
	}
}


iframe {
	width: 100%;
	margin: 30px 0px;
}

.footer {
	margin-top: 20px;
	padding: 10px;
	background-color: $footer_color;
	h3 {
		color: $color_base_white !important;
	}
	ul {
		padding-left: 10px;
		li {
			color: $color_base_white;
			display: block;
			a {
				color: #fff;
				
			}
		}
	}
	.social-icon {

		li {
			display: inline-block;
			a {
			    vertical-align: middle;
			    display: inline-block;
			    font-size: 12px;
			    background-color: $blue;
			    padding: 10px;
			    border-radius(50%);
			    width: 35px;
			    height: 35px;
			    text-align: center;
			}
		}
	}
	input {
	    width: 100%;
	    background-color: $blue;
	    border: none;
	    border-radius: 6px;
	    padding: 10px;
	    outline: none;
	    color: #fff;
	    font-size: 1rem;
	}
	input:-moz-placeholder {
		color: $color_base_white;
	}
	input::-moz-placeholder {
		color: $color_base_white;
	}
	input:-ms-input-placeholder {
		color: $color_base_white;
	}
	input::-webkit-input-placeholder {
		color: $color_base_white;
	}
}

.partners {

	&-row {
			margin: 10px 0px;
		text-align: justify;
		&:after {
			content: '';
	    	width: 100%;
	    	display: inline-block;
		}
	}
	
	img {
		height: 60px;
		max-width: 100%;
	}
	
	.regular.slider {
		margin-top: 16px;
		.slick-slide {
			width: auto!important;
			margin-right: 50px;
		}
	}
}
.handwrite {
	font-family: 'simple_joysregular';
	size(50px, 0px, auto);
}
.button {
	color: $color_base_white;
	border-radius(6px);
	&-small {
		size(18px, 10px, 150px);
	}
	&-medium {
		size(24px, 10px, 40%);
	}
	&-pink {
		background-color: $pink;
	}
	&-blue {
		background-color: $blue;
	}
	&-white {
		background-color: $color_base_white;
		color: $pink;
	}
	border: none;
}
.select {
	border: none;
	border: 1px solid $pink;
	&-medium {
		size(18px, 10px, 260px);
	}
}

.affix {
	top: 0px;
	background-color: $color_base_white;
	z-index: 1000;
    width: 100%;
    padding: 10px;
}
.block {
	display: block;
}

.mt {
	mt(20px);
}
.mb {
	mb(20px);
}
.review {
	color: $pink;
	padding: 10px 0px;
	h3 {
		color: $pink;
	}
	&-user {
		i {
			background-color: #e6e6e6;
			padding: 10px 14px;
			border-radius(50%);
			width: 50px;
			height: 50px;
		}
	}
	span {
		display: block;
	}
}

#live-chat { 
	background-color: $pink;
	padding-bottom: 15px;
}
.fav-btn {
	position: absolute;
	bottom: 15px;
	padding-left: 15px;
	width: 100%;
	a {
		position: relative;
	}
}