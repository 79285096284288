.claim-steps {
		& > div {
			background-color: lighten($pink, 30%);
			padding: 10px 0px;
		}
		& > .active {
			background-color: $pink;
		}
		font-size: 24px;
		color: $color_base_white;
	}
.claim {

	&-options {
		h4 {
			color: $blue;
			font-weight: 600;
		}
	}
	.absolute {
		bottom: -180px;
    	width: auto;
	}
	.open-slider {
		float: left;
		z-index: 100;
		margin-bottom: 15px;
	}
	.claim-description {
		font-size: 24px;
		float: left;
		color: $pink;
		width: 39%;
		padding-left: 15px;
		position: absolute;
    	right: 15px;
    	z-index: 1;
	}
	.show {
		width: 60%;
	}
}
.claimv2 {
	iframe {
		margin: 0px;
	}
	.open-slider {
		height: 180px;
		z-index: 100;
		margin-bottom: 15px;
		.absolute {
			bottom: 15px;
			right: 15px;
			width: auto;
		}
	}
	.show {
		width: 60%!important;
	}
	.claim-description {
		font-size: 16px;
	    float: left;
	    color: #ff80ab;
	    width: 39%;
	    padding-left: 15px;
	    position: absolute;
	    right: 15px;
	    z-index: 1;
	    top: 0px;
	}
}
.final {
	&-steps {
		& > div {
			background-color: lighten($pink, 30%);
			padding: 10px 0px;
		}
		& > .active {
			background-color: $pink;
		}
		font-size: 24px;
		color: $color_base_white;
	}
	&-tab {
		padding: 10px 0px;
	}
	h4 {
			color: $pink;
		}
	.payment-type {

		text-align:center;
		.btn {
			width: 300px;
		}
	}
	.amount {
		span {
			display: inline-block;
			width: 25%;
			float: left;
    		font-size: 12px;
    		text-align:center;
    		&:first-child {
    			text-align:left;
    		}
    		&:last-child {
    			text-align:right;
    		}
		}
	}
	.first-step {
		label {
			color: $pink;
		}
	}
}
