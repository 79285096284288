.open-slider {
	display: inline-block;
	width: 100%;
	position: relative;
	height: 450px;
	background-image: url('../images/backgrounds/2.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	.photos {
		position: absolute;
	    bottom: 0px;
	    right: 0px;
	    left: 0px;
	    text-align: right;
	    z-index: 100;
	    a {
	    	color: $color_base_white;
			z-index: 100;
	    }
	}
	.cancel {
		position: absolute;
		bottom: 15px;
		left: 15px;
		
	}
}
.banner {
	background-image: url('../images/banner1.jpg');
	background-repeat: no-repeat;
	height: 200px;
	position: relative;
	margin-bottom: 30px;
	background-size: 100% 100%;
	text-align: center;
    position: relative;
    padding-top: 15px;
	&-img {
	}
}
// TODO : Redo all this discount shit
.venues {
	background-color: $blue;
    .open-slider {
    	margin: 15px 0px;
    	border-radius(10px);
    }
    .absolute {
		bottom: -230px;
		width: auto;
	}
	h3 {
		font-size: 28px;
	}
	h4 {
		font-size: 24px;
		background-color: $color_base_white;
		color: $pink;
		padding: 5px 10px;
	}
}

.venue-bubble {
	background-color: $pink;
	color: $color_base_white;
	float: left;
	text-align: center;
	border-radius(50%);
	width: 70px;
	height: 70px;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 10px;
}
.category {
	margin-left: 200px;

}
.money {
	display: block;
	width: 100px;
	background-image: url('../images/money.png');
	background-repeat: no-repeat;
	background-size: 100%;
	&-expensive {
		height: 56px;
	}
	&-medium {
		height: 44px;
	}
	&-affordable {
		height: 24px;
	}
}
.venue {	
	
	h2 {
		span {
			font-size: 16px;
		}
	}
	.absolute {
		bottom: 10px;
		width: auto;
	}

	&-icons {
		i {
			border-radius(50%);
			background-color: $color_base_white;
			color: $blue;
			padding: 14px;
		}
	}
	&-title {
		font-weight: 600;
		mt(20px);
	}
	&-scheduel {
		color: $color_base_white;
	}
	&-type {
		font-size: 16px;
		margin-top: 5px;
		span {
			display: block;
		}
	}
	#venue {
		.modal {
			&-content {
				background-color: transparent;
				text-align: center;
				box-shadow: none;
    			border: none;
			}
			&-footer {
				display: inline-block;
				border: none;
				text-align: center;
			}
			&-header {
				border: none;
			}
		}
		
		//Force images on venu page to stay center
		.carousel-inner > .item > img, .carousel-inner > .item > a > img {
			margin: 0 auto;
		}
		.carousel-control {
			&.right,
			&.left {
				background-image: none;
			}
		}
	}
	#map {
		margin: 20px 0px;
	}
	.form {
		textarea {
			height: 135px;
		}
	}

}